<template>
  <v-select
    @input="handleInput"
    class="style-chooser single"
    :value="value"
    :placeholder="field.placeholder"
    :options="options"
    :reduce="(option) => option.id"
    label="name"
  ></v-select>
</template>

<script>
import Vue from 'vue';
import vSelect from 'vue-select';
// import 'vue-select/dist/vue-select.css';
import 'vue-select/src/scss/vue-select.scss';
Vue.component('v-select', vSelect);

export default {
  props: ['field', 'value'],

  data() {
    return {
      model: this.value,
    };
  },

  computed: {
    options() {
      return this.field && this.field.options ? this.field.options : [];
    },
  },

  methods: {
    handleInput(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  /* border: 1px solid;
  border-color: rgba(236, 236, 236, var(--tw-border-opacity));
  border-radius: 2px; */
  border: none;
}

.style-chooser .vs__dropdown-toggle {
  height: 44px;
  padding: 0px;
}

.style-chooser .vs__search::placeholder {
  border: none;
  @apply text-gray-600 opacity-100;
}

.style-chooser .vs__search {
  padding-left: 12px;
  padding-right: 4px;
  margin: 0px;
}

.style-chooser .vs__actions {
  transform: scale(0.7);
}

.style-chooser .vs__selected {
  padding-left: 9px;
  padding-right: 6px;
  margin-left: 3px;
}

.single .vs__selected {
  height: 100%;
  margin-top: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: flex;
}

.multiple .vs__selected {
  margin-top: 3px;
  margin-bottom: 3px;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #b0b4bf;
}
</style>

import consumer from './consumer'

var AuctionRoomsChannelInstance = consumer.subscriptions.create('AuctionRoomsChannel', {
  current_user: null,
  room_id: function(){
    return $('#auction-room-page').data('room-name');
  },
  connected() {
    // Called when the subscription is ready for use on the server
    var self = this;
    self.current_user = $('#auction-room-page').data('user-id');

    setTimeout(function(){
      self.join();
    }, 1000);
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  join: function(){
    this.perform('join', {room: this.room_id()});
  },

  received: function(data){
    // Called when there's incoming data on the websocket for this channel
    console.log(data);

    if (data.action_type == 'allow_bid') {
      if (data.user_id == this.current_user) {
        // AuctionRoom.allowBid();
        var $nhapGiaWrapper = $('#nhap-gia-wrapper');

        $nhapGiaWrapper.find('input').removeAttr('disabled');
        $nhapGiaWrapper.find('button').removeAttr('disabled');
      }
    } else if (data.action_type == 'join') {
      $('#participant-wrapper-' + data.user_room_sid).data('userId', data.user_id);

      if (data.user_id != this.current_user && data.user_room_sid != data.dau_gia_vien_room_sid && $('#participant-wrapper-' + data.user_room_sid).length > 0) {
        $('<p>User: ' + data.user_id + '</p>').appendTo('#participant-wrapper-' + data.user_room_sid);
      }

      if (data.dau_gia_vien_room_sid) {
        $('#auction-room-page').data('dgvSid', data.dau_gia_vien_room_sid);

        //Move video of dau_gia_vien if needed.
        if ($('#participant-wrapper-' + data.dau_gia_vien_room_sid).length > 0){
          $('#participant-wrapper-' + data.dau_gia_vien_room_sid).appendTo('#dau-gia-vien-video');
        }
      }

      var $roomLogs = $('#room-logs');
      $roomLogs.append(data.html);
    } else {
      var $roomLogs = $('#room-logs');
      $roomLogs.append(data.html);
    }
    
    return;
  },
});

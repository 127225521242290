var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"w-full"},[(_vm.field.type === 'media_upload')?_c('div',[_c('Field',{attrs:{"validate":_vm.validate,"value":_vm.value,"field":_vm.field},on:{"changeImages":_vm.changeImage,"input":(val) => {
          _vm.$emit('input', val);
          return (_vm.model = val);
        }}}),_vm._v(" "),(_vm.validate !== true && _vm.validate !== undefined)?_c('small',{staticClass:"absolute description text-[#DA0303]",class:_vm.field.type === 'media_upload' || _vm.field.type === 'textarea'
          ? ''
          : 'mt-[3px]'},[_vm._v("\n      "+_vm._s(_vm.validate === false ? _vm.field.content : _vm.validate)+"\n    ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(
      _vm.field.type === 'text' ||
      _vm.field.type === 'email' ||
      _vm.field.type === 'password' ||
      _vm.field.type === 'textarea' ||
      _vm.field.type === 'number' ||
      _vm.field.type === 'price' ||
      _vm.field.type === 'date'
    )?_c('div',{staticClass:"grid",class:_vm.classGrid ? _vm.classGrid : ' grid-cols-7 gap-x-[2rem] gap-y-[2px]'},[(_vm.field.label)?_c('label',{staticClass:"font-medium text-gray-800 body-text",class:_vm.classCol ? _vm.classCol : 'col-span-full md:col-span-2 '},[_c('span',{class:_vm.classLabel ? _vm.classLabel : 'text-gray-800',domProps:{"innerHTML":_vm._s(_vm.field.label)}}),(_vm.isRequired)?_c('span',{class:_vm.classRequired ? _vm.classRequired : 'text-[#FF3838]'},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative",class:_vm.field.label
          ? `${_vm.classCol ? _vm.classCol : 'col-span-full md:col-span-5'}`
          : 'col-span-full'},[_c('Field',{attrs:{"value":_vm.value,"field":_vm.field,"validate":_vm.validate},on:{"action":function($event){return _vm.$emit('action')},"input":(val) => (_vm.model = val)}}),_vm._v(" "),(_vm.validate !== true && _vm.validate !== undefined)?_c('small',{staticClass:"absolute left-0 description",class:[
          _vm.field.type === 'media_upload' || _vm.field.type === 'textarea'
            ? ''
            : 'mt-[3px]',
          _vm.field.colorMessage ? _vm.field.colorMessage : 'text-[#DA0303]',
        ]},[_vm._v("\n        "+_vm._s(_vm.validate === false ? _vm.field.content : _vm.validate)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.field.type === 'password' && _vm.validate && !_vm.noNoti)?_c('small',{staticClass:"absolute text-gray-700 description mt-[3px]"},[_vm._v("\n        Mật khẩu phải chứa ít nhất 8 ký tự, 1 viết hoa, 1 viết thường, 1 số\n      ")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.field.type === 'radio')?_c('div',{staticClass:"relative grid",class:_vm.classGrid ? _vm.classGrid : ' grid-cols-7 gap-x-[2rem] gap-y-[2px]'},[(_vm.field.label)?_c('label',{staticClass:"font-medium text-gray-800 body-text",class:_vm.classCol ? _vm.classCol : 'col-span-full md:col-span-2 '},[_c('span',{class:_vm.classLabel ? _vm.classLabel : 'text-gray-800',domProps:{"innerHTML":_vm._s(_vm.field.label)}}),_c('span',{staticClass:"text-[#FF3838]"},[_vm._v("*")])]):_vm._e(),_vm._v(" "),_c('Field',{attrs:{"field":_vm.field},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_vm._v(" "),(_vm.validate !== true && _vm.validate !== undefined)?_c('small',{staticClass:"absolute left-0 top-full description",class:[
        _vm.field.type === 'media_upload' || _vm.field.type === 'textarea'
          ? ''
          : 'mt-[3px]',
        _vm.field.colorMessage ? _vm.field.colorMessage : 'text-[#DA0303]',
      ]},[_vm._v("\n      "+_vm._s(_vm.validate === false ? _vm.field.content : _vm.validate)+"\n    ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.field.type === 'check-box')?_c('div',[_c('Field',{attrs:{"field":_vm.field},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),_vm._v(" "),(_vm.field.type === 'radio_control')?_c('div',[_c('Field',{attrs:{"field":_vm.field},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),_vm._v(" "),(_vm.field.type === 'checkbox_tree')?_c('div',[_c('Field',{attrs:{"field":_vm.field},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),_vm._v(" "),(_vm.field.type === 'select_single')?_c('div',[_c('div',{staticClass:"grid gap-x-[2rem] gap-y-[2px]",class:_vm.classGrid ? _vm.classGrid : 'grid-cols-7'},[_c('label',{staticClass:"font-medium body-text",class:_vm.classCol ? _vm.classCol : 'col-span-full md:col-span-2'},[_vm._v("\n        "+_vm._s(_vm.field.label)+"\n        "),(_vm.isRequired)?_c('span',{staticClass:"text-[#FF3838]"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"relative w-full border border-solid border-gray-400 rounded-[2px]",class:[
          _vm.field.label
            ? `${_vm.classCol ? _vm.classCol : 'col-span-full md:col-span-5'}`
            : 'col-span-full',
          _vm.bacground,
          { error: !_vm.validate },
        ]},[_c('Field',{attrs:{"value":_vm.value,"validate":_vm.validate,"field":_vm.field},on:{"input":(val) => (_vm.model = val)}}),_vm._v(" "),(_vm.validate !== true && _vm.validate !== undefined)?_c('small',{staticClass:"absolute left-0 top-[47px] description text-[#DA0303]"},[_vm._v("\n          "+_vm._s(_vm.validate === false ? _vm.field.content : _vm.validate)+"\n        ")]):_vm._e()],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
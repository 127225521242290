<template>
  <div class="w-full space-y-3">
    <div
      v-if="field.label"
      class="font-medium text-black text-xs leading-[120%]"
    >
      {{ field.label }}
      <span v-if="isRequired && field.label" class="text-[#FF3838]">*</span>
    </div>
    <div>
      <input
        id="file"
        type="file"
        class="hidden inputfile"
        :accept="typeConfig ? typeConfig.accept : ''"
        ref="image"
        @change="fileChange"
        :multiple="multiple"
      />
      <label
        for="file"
        class="w-full p-3 space-y-1 rounded-xl bg-gray-c-l2"
        :class="bg"
        @click.prevent="browse"
      >
        <div class="text-xs font-bold leading-[120%] text-gray-c-d1">
          <div></div>
          {{ typeConfig.text }}
        </div>
        <div class="text-gray-600 description">
          {{
            images && images.length > 0
              ? `${images.length} tệp được chọn`
              : 'Không có tệp nào được chọn'
          }}
        </div>
      </label>
    </div>
    <div
      v-if="
        field && field.config.showType === 'text' && images && images.length
      "
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        class="flex space-x-[8px] items-center"
      >
        <div
          class="flex-none cursor-pointer w-[1rem] h-[1rem] bg-black rounded-full flex justify-center"
          @click="removeImage(index)"
        >
          <svg
            class="my-auto"
            width="7"
            height="7"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.05495 0.912598L0.876953 6.07764"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.05495 6.07764L0.876953 0.912598"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>
          <div class="font-medium caption line-clamp-1">{{ image.name }}</div>
          <div class="text-gray-700 description" v-if="image.size">
            {{ filesizeToText(image.size) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="images && images.length > 0"
      :class="multiple ? 'grid-cols-3' : 'grid-cols-2 '"
      class="grid gap-[8px] mt-[8px]"
    >
      <div
        v-for="(image, index) in images"
        :key="index"
        class="relative min-h-[140px]"
      >
        <div
          class="rounded-[2px] overflow-hidden"
          v-if="
            image.type === 'image/jpeg' ||
            image.type === 'image/png' ||
            image.type === 'image/gif'
          "
        >
          <div class="aspect-w-3 aspect-h-2">
            <img :src="image.src" class="object-contain w-full h-full" />
          </div>
          <div
            class="absolute bottom-0 left-0 px-[16px] pb-[8px] pt-[12px] w-full text-white background-linear"
          >
            <div class="font-medium caption line-clamp-2">{{ image.name }}</div>
            <div class="text-gray-100 description" v-if="image.size">
              {{ filesizeToText(image.size) }}
            </div>
          </div>
        </div>
        <div
          class="bg-green-100 border border-green-200 border-solid rounded-[2px] w-full h-full"
          v-else
        >
          <div class="pl-[16px] pt-[8px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.7125 10.2875L19.7125 3.28752C19.522 3.10179 19.266 2.99852 19 3.00002H7C6.46957 3.00002 5.96086 3.21073 5.58579 3.5858C5.21071 3.96088 5 4.46958 5 5.00002V27C5 27.5304 5.21071 28.0392 5.58579 28.4142C5.96086 28.7893 6.46957 29 7 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0392 27 27.5304 27 27V11C27.0015 10.734 26.8982 10.478 26.7125 10.2875ZM19 11V5.50002L24.5 11H19Z"
                fill="#179668"
              />
            </svg>
          </div>
          <div
            class="absolute bottom-0 left-0 px-[16px] py-[8px] w-full text-black"
          >
            <div class="font-medium caption line-clamp-2">{{ image.name }}</div>
            <div class="description" v-if="image.size">
              {{ filesizeToText(image.size) }}
            </div>
          </div>
        </div>
        <div
          @click="removeImage(index)"
          class="absolute cursor-pointer right-[8px] top-[8px] w-[1rem] h-[1rem] bg-white rounded-full flex justify-center"
        >
          <svg
            class="my-auto"
            width="7"
            height="7"
            viewBox="0 0 7 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.05495 0.912598L0.876953 6.07764"
              stroke="#2A2A2A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.05495 6.07764L0.876953 0.912598"
              stroke="#2A2A2A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'title'],
  emits: ['change'],

  data() {
    return {
      images: [],
      isRequired: false,
    };
  },

  computed: {
    multiple() {
      return this.field.config && this.field.config.multiple
        ? this.field.config.multiple
        : false;
    },

    bg() {
      return this.field.config && this.field.config.background
        ? this.field.config.background
        : 'bg-gray-100';
    },

    acceptType() {
      return this.field.config && this.field.config.accept
        ? this.field.config.accept
        : '';
    },

    typeConfig() {
      return (
        {
          image: {
            accept: 'image/png, image/gif, image/jpeg',
            text: 'Chọn hình ảnh',
            maxsize: 5,
          },
          video: {
            accept: 'video/mp4,video/x-m4v,video/*',
            text: 'Chọn video',
            maxsize: 100,
          },
          pdf: {
            accept: 'application/pdf, application/msword',
            text: 'Chọn hồ sơ',
            maxsize: 20,
          },
          file: {
            accept:
              '.doc, .docx, application/msword, application/pdf, image/png, image/gif, image/jpeg',
            text: 'Chọn files',
            maxsize: 20,
          },
        }[this.acceptType] || {
          accept: '',
          text: 'Chọn files',
          maxsize: 5,
        }
      );
    },
  },

  watch: {
    value: {
      deep: true,
      handler: function () {
        if (
          (Array.isArray(this.value) && this.value.length === 0) ||
          this.value === null ||
          Object.keys(this.value).length === 0
        ) {
          this.images = [];
        }
      },
    },
  },

  created() {
    this.setImages();
    this.isRequired = `${this.field.rules}`.includes('required');
  },

  methods: {
    browse() {
      if (this.field && this.field.disable) {
        return;
      }
      this.$refs.image.click();
    },

    fileChange() {
      if (!this.multiple) {
        this.images = [];
      }
      let images = this.$refs.image.files;

      if (images.length === 0) return;
      // this.images.splice(0, this.images.length);

      for (const image of images) {
        if (!this.filesizeValid(image)) {
          alert(
            `Dung lượng file tối đa là ${this.typeConfig.maxsize}MB. Vui lòng thử lại.`
          );
          this.$refs.image.value = '';
          return false;
        }
      }

      for (let index = 0; index < images.length; index++) {
        const image = images[index];

        image.src = URL.createObjectURL(image);

        this.images.push(image);
      }
      this.$refs.image.value = '';

      this.uploadImages(this.images);
    },

    uploadImages(images) {
      let res = null;
      if (!this.multiple) {
        res = images[0] || null;
      } else {
        res = images;
      }
      this.emitImages(res);
    },

    removeImage(index) {
      this.images.splice(index, 1);
      this.uploadImages(this.images);
    },

    filesizeValid(file) {
      const fileSize = file.size / 1024 / 1024;
      return fileSize <= this.typeConfig.maxsize;
    },

    emitImages(images) {
      this.$emit('change', images);
    },

    filesizeToText(size) {
      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const x = size;
      let l = 0,
        n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    },

    setImages() {
      if (this.value && this.value.length > 0) {
        this.value.forEach((element) => {
          let image = element;
          image.src = element.url;
          image.name = element.filename;
          this.images.push(image);
        });
      }

      if (!this.multiple && this.value && Object.keys(this.value).length > 0) {
        let image = this.value;
        image.src = URL.createObjectURL(image);
        this.images.push(image);
      }
    },
  },
};
</script>

<style scoped>
.inputfile {
  @apply outline-none select-none;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  display: inline-block;
  cursor: pointer;
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.icon {
  padding: 0 6px 0 0;
}

.key {
  color: red;
}

.background-linear {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
</style>

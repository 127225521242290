// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './styles/application.css';

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('./images', true);
// const imagePath = (name) => images(name, true)

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
// require('jquery');
// require('auction_room');

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Vue from 'vue';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import MatomoTracker from '@/utils/matomo-tracker';
Vue.use(MatomoTracker);

import VModal from 'vue-js-modal';
Vue.use(VModal);

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  duration: 5000,
  position: 'top-center',
});

import { createInertiaApp } from '@inertiajs/inertia-vue';
import { Head, Link } from '@inertiajs/inertia-vue';
import { InertiaProgress } from '@inertiajs/progress';
Vue.component('InertiaHead', Head);
Vue.component('InertiaLink', Link);
InertiaProgress.init();

import * as Routes from '@/utils/routes';
Vue.prototype.$routes = Routes;

const eventBus = new Vue();
Vue.prototype.$bus = eventBus;

// Import global components
import Fieldset from '@/Shared/Fields/Fieldset.vue'
Vue.component('Fieldset', Fieldset);

createInertiaApp({
  resolve: (name) =>
    import(`Pages/${name}`).then(({ default: component }) => {
      if (Vue.matomo.enabled)
        // Wait a bit to allow VueMeta to update the document.title
        setTimeout(() => {
          Vue.matomo.trackPageView();
        }, 100);

      return component;
    }),

  setup({ el, app, props }) {
    new Vue({
      metaInfo: {
        titleTemplate: (title) => (title ? `${title}` : 'Đấu giá Trường sơn'),
      },
      render: (h) => h(app, props),
    }).$mount(el);
  },
});

<template>
  <fieldset class="w-full">
    <div v-if="field.type === 'media_upload'">
      <Field
        :validate="validate"
        :value="value"
        @changeImages="changeImage"
        @input="
          (val) => {
            $emit('input', val);
            return (model = val);
          }
        "
        :field="field"
      />
      <small
        v-if="validate !== true && validate !== undefined"
        class="absolute description text-[#DA0303]"
        :class="
          field.type === 'media_upload' || field.type === 'textarea'
            ? ''
            : 'mt-[3px]'
        "
      >
        {{ validate === false ? field.content : validate }}
      </small>
    </div>
    <div
      v-if="
        field.type === 'text' ||
        field.type === 'email' ||
        field.type === 'password' ||
        field.type === 'textarea' ||
        field.type === 'number' ||
        field.type === 'price' ||
        field.type === 'date'
      "
      class="grid"
      :class="classGrid ? classGrid : ' grid-cols-7 gap-x-[2rem] gap-y-[2px]'"
    >
      <label
        v-if="field.label"
        class="font-medium text-gray-800 body-text"
        :class="classCol ? classCol : 'col-span-full md:col-span-2 '"
      >
        <span
          :class="classLabel ? classLabel : 'text-gray-800'"
          v-html="field.label"
        ></span
        ><span
          v-if="isRequired"
          :class="classRequired ? classRequired : 'text-[#FF3838]'"
          >*</span
        >
      </label>
      <div
        :class="
          field.label
            ? `${classCol ? classCol : 'col-span-full md:col-span-5'}`
            : 'col-span-full'
        "
        class="relative"
      >
        <Field
          @action="$emit('action')"
          :value="value"
          @input="(val) => (model = val)"
          :field="field"
          :validate="validate"
        />
        <small
          v-if="validate !== true && validate !== undefined"
          class="absolute left-0 description"
          :class="[
            field.type === 'media_upload' || field.type === 'textarea'
              ? ''
              : 'mt-[3px]',
            field.colorMessage ? field.colorMessage : 'text-[#DA0303]',
          ]"
        >
          {{ validate === false ? field.content : validate }}
        </small>
        <small
          v-if="field.type === 'password' && validate && !noNoti"
          class="absolute text-gray-700 description mt-[3px]"
        >
          Mật khẩu phải chứa ít nhất 8 ký tự, 1 viết hoa, 1 viết thường, 1 số
        </small>
      </div>
    </div>
    <div
      v-if="field.type === 'radio'"
      class="relative grid"
      :class="classGrid ? classGrid : ' grid-cols-7 gap-x-[2rem] gap-y-[2px]'"
    >
      <label
        v-if="field.label"
        class="font-medium text-gray-800 body-text"
        :class="classCol ? classCol : 'col-span-full md:col-span-2 '"
      >
        <span
          :class="classLabel ? classLabel : 'text-gray-800'"
          v-html="field.label"
        ></span
        ><span class="text-[#FF3838]">*</span>
      </label>
      <Field v-model="model" :field="field" />

      <small
        v-if="validate !== true && validate !== undefined"
        class="absolute left-0 top-full description"
        :class="[
          field.type === 'media_upload' || field.type === 'textarea'
            ? ''
            : 'mt-[3px]',
          field.colorMessage ? field.colorMessage : 'text-[#DA0303]',
        ]"
      >
        {{ validate === false ? field.content : validate }}
      </small>
    </div>
    <div v-if="field.type === 'check-box'">
      <Field v-model="model" :field="field" />
    </div>
    <div v-if="field.type === 'radio_control'">
      <Field v-model="model" :field="field" />
    </div>
    <div v-if="field.type === 'checkbox_tree'">
      <Field v-model="model" :field="field" />
    </div>
    <div v-if="field.type === 'select_single'">
      <div
        class="grid gap-x-[2rem] gap-y-[2px]"
        :class="classGrid ? classGrid : 'grid-cols-7'"
      >
        <label
          class="font-medium body-text"
          :class="classCol ? classCol : 'col-span-full md:col-span-2'"
        >
          {{ field.label }}
          <span v-if="isRequired" class="text-[#FF3838]">*</span>
        </label>
        <div
          class="relative w-full border border-solid border-gray-400 rounded-[2px]"
          :class="[
            field.label
              ? `${classCol ? classCol : 'col-span-full md:col-span-5'}`
              : 'col-span-full',
            bacground,
            { error: !validate },
          ]"
        >
          <Field
            :value="value"
            @input="(val) => (model = val)"
            :validate="validate"
            :field="field"
          />
          <small
            v-if="validate !== true && validate !== undefined"
            class="absolute left-0 top-[47px] description text-[#DA0303]"
          >
            {{ validate === false ? field.content : validate }}
          </small>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { validateField } from '@/validator.js';
import Field from '@/Shared/Fields/Field';
import ArrowResult from '@/Icons/ArrowResult.vue';
export default {
  components: {
    Field,
    ArrowResult,
  },
  props: [
    'field',
    'value',
    'classGrid',
    'classCol',
    'classLabel',
    'classRequired',
  ],

  data() {
    return {
      model: this.value,
      isRequired: false,
      validate: this.field.validate === false ? this.field.validate : true,
      label: this.field.label ? this.field.label.replace('<br />', '') : '',
    };
  },

  computed: {
    noNoti() {
      return this.field && this.field.noNoti ? this.field.noNoti : false;
    },

    bacground() {
      return this.field && this.field.bg ? this.field.bg : '';
    },
  },

  watch: {
    model: {
      deep: true,
      handler(value) {
        this.validate = validateField(value, this.field.rules);
        this.$emit('input', value);
        this.$emit('changeValue');
      },
    },

    value() {
      if (this.field.type === 'check-box') {
        this.model = this.value;
      }
    },

    'field.validate'() {
      this.validate = this.field.validate;
    },
  },

  created() {
    this.isRequired = `${this.field.rules}`.includes('required');
  },

  methods: {
    changeImage(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.error {
  --tw-border-opacity: 1;
  border-color: rgba(144, 35, 20, var(--tw-border-opacity));
}
</style>

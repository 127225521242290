<template>
  <section>
    <div
      v-if="
        field.type === 'text' ||
        field.type === 'email' ||
        field.type === 'password' ||
        field.type === 'date'
      "
      class="relative"
    >
      <input
        v-if="field.type !== 'date'"
        :type="eye ? 'text' : field.type"
        :value="value"
        :name="fieldId"
        @input="handleInput"
        :placeholder="field.placeholder"
        :disabled="field.disabled ? true : false"
        :id="fieldId"
      />
      <input
        v-else
        :type="model ? 'date' : 'text'"
        onfocus="(this.type='date')"
        :value="value"
        :name="fieldId"
        @input="handleInput"
        max="9999-12-31"
        :placeholder="field.placeholder"
        :disabled="field.disabled ? true : false"
        :id="fieldId"
      />
      <label :for="fieldId" class="cursor-pointer">
        <Calendar
          v-if="field.type === 'date'"
          class="absolute top-0 right-[16px] h-full"
        />
      </label>
      <Eye
        v-if="field.type === 'password'"
        :open="eye"
        class="absolute top-1/2 right-[12px] -translate-y-1/2 z-10 cursor-pointer"
        @action="changeEye"
      />
    </div>

    <div v-if="field.type === 'number'" class="relative">
      <input
        :type="field.type"
        inputmode="numeric"
        autocomplete="off"
        :disabled="field.disabled ? true : false"
        :value="value"
        :id="fieldId"
        @input="handleInput"
        :placeholder="field.placeholder"
        :min="field.min"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      />
    </div>

    <div v-if="field.type === 'price'" class="relative">
      <input
        type="text"
        inputmode="numeric"
        autocomplete="off"
        :disabled="field.disabled ? true : false"
        :value="valueMaker"
        :id="fieldId"
        @input="handleInput"
        :placeholder="field.placeholder"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
      />
    </div>

    <div v-if="field.type === 'textarea'">
      <textarea
        class="p-[8px] text-black"
        :type="field.type"
        :value="value"
        :placeholder="field.placeholder"
        @input="handleInput"
        cols="30"
        :rows="`${!!field.rowsArea ? field.rowsArea : 7}`"
        :id="fieldId"
      />
    </div>

    <template v-if="field.type === 'media_upload'">
      <UploadFile :value="value" :field="field" @change="inputChange" />
    </template>

    <template v-if="field.type === 'radio'">
      <div
        v-if="field.options && field.options.length > 0"
        class="relative flex w-max"
        :class="
          field.direction === 'col'
            ? 'flex-col w-full space-y-[8px]'
            : 'flex-row space-x-3'
        "
      >
        <template v-for="(item, index) in field.options">
          <div
            :key="index"
            class="radio"
            :class="
              modelValue === item.id
                ? 'text-red-500 border-red-500'
                : 'text-gray-700 border-gray-200'
            "
          >
            <input
              :id="`${fieldId}${index}`"
              class="border border-gray-500 radio-item"
              type="radio"
              v-model="modelValue"
              :name="`${fieldId}`"
              :value="item.id"
            />
            <label
              class="block h-full body-text radio-label w-max"
              :for="`${fieldId}${index}`"
              v-html="item.label"
            />
            <span class="checkmark" />
          </div>
        </template>
      </div>
      <div v-else class="flex items-center space-x-[8px]">
        <input
          class="w-[18px] h-[18px]"
          type="radio"
          :id="fieldId"
          :value="field.value"
          v-model="modelValue"
          :name="field.name"
        />
        <label
          v-if="field.label"
          :for="fieldId"
          class="relative self-center font-normal text-gray-900 body-text"
        >
          <span v-html="field.label" />
        </label>
      </div>
    </template>
    <template v-if="field.type === 'select_single'">
      <SingleSelect :value="value" :field="field" @change="selectChange" />
    </template>

    <template v-if="field.type === 'checkbox_tree'">
      <div v-for="(item, index) in field.options" :key="index">
        <div class="checkbox">
          <input
            :disabled="field.disabled ? true : false"
            :id="`${fieldId}_${index}`"
            type="checkbox"
            v-model="modelValue"
            :value="item.id"
            :indeterminate.prop="containsSome(modelValue, pluck(item.items))"
            @input="onChangeCheckbox($event, item)"
          />
          <label :for="`${fieldId}_${index}`" class="font-bold">
            {{ item.name }}
          </label>
          <span />
        </div>

        <div v-if="item.items" class="ml-[24px] grid grid-cols-6 py-2">
          <div
            v-for="(child, childIndex) in item.items"
            :key="childIndex"
            class="px-3 checkbox"
          >
            <input
              :id="`${fieldId}_${index}_${childIndex}`"
              v-model="modelValue"
              type="checkbox"
              :value="child.id"
              :disabled="field.disabled ? true : false"
              @input="onChangeCheckbox($event, child, pluck(item.items))"
            />
            <label :for="`${fieldId}_${index}_${childIndex}`">
              {{ child.name }}
            </label>
            <span />
          </div>
        </div>
      </div>
    </template>

    <label
      v-if="field.type === 'check-box'"
      :for="fieldId"
      :class="[
        'flex cursor-pointer checkbox-single',
        { 'opacity-50': field.disabled },
      ]"
    >
      <input
        type="checkbox"
        :id="fieldId"
        @change="handleInputCheckbox"
        :name="fieldId"
        :checked="value"
        :disabled="field.disabled ? true : false"
        class="absolute flex-none w-0 h-0 opacity-0"
      />
      <div class="relative self-center font-semibold text-gray-c-d1 body-text">
        <span v-html="field.label" />
        <div
          v-if="field.help"
          class="absolute left-0 text-orange-500 description"
        >
          {{ field.help }}
        </div>
      </div>
      <span class="checkmark">
        <img :src="require('@/images/icon/unchecked.png')" class="unchecked" />
        <img :src="require('@/images/icon/checked.png')" class="checked" />
      </span>
    </label>
  </section>
</template>

<script>
import UploadFile from '../Account/UploadFile.vue';
import Eye from '../../Icons/Eye.vue';
import Calendar from '@/Icons/Calendar.vue';
import SingleSelect from '@/Shared/Fields/SingleSelect';
export default {
  components: { UploadFile, Eye, Calendar, SingleSelect },
  props: ['field', 'value', 'validate'],
  data() {
    return {
      model: this.value,
      fieldId: '',
      modelValue: this.value,
      eye: false,
    };
  },
  computed: {
    valueMaker() {
      if (this.field.type === 'price') {
        let n = parseInt(this.value);
        return n ? n.toLocaleString('de-DE') : null;
      }
      return this.value;
    },
  },
  watch: {
    modelValue(value) {
      this.$emit('input', value);
    },
    validate() {
      const element = document.getElementById(this.fieldId);
      if (this.validate !== true && this.validate !== undefined) {
        element ? (element.style.borderColor = '#E30000') : '';
      } else {
        element ? (element.style.borderColor = '#D3D3D4') : '';
      }
    },
  },
  created() {
    this.fieldId = Math.random().toString(36).substr(2, 9);

    if (this.field.type === 'checkbox_tree') {
      this.field.options.forEach((item) => {
        if (this.containsAll(this.modelValue, this.pluck(item.items))) {
          this.modelValue.push(item.id);
        }
      });
    }
  },
  methods: {
    handleInput(e) {
      let value = null;
      if (this.field.type === 'price') {
        value = e.target.value
          ? parseInt(e.target.value.replace(/\D/g, ''), 10)
          : null;
      } else {
        value = e.target.value;
      }

      this.$emit('input', value);
    },
    handleInputCheckbox(e) {
      this.$emit('input', e.target.checked);
    },
    inputChange(value) {
      this.$emit('changeImages', value);
    },

    selectChange(val) {
      this.$emit('input', val);
    },

    changeEye() {
      this.eye = !this.eye;
    },

    containsSome(parents, childs) {
      const hasSome = parents.some((id) => childs.includes(id));
      const hasAll = this.containsAll(parents, childs);
      return hasSome && !hasAll;
    },

    containsAll(parents, childs) {
      return childs.every((i) => parents.includes(i));
    },

    onChangeCheckbox(event, item, siblingIds = []) {
      let values = [...this.modelValue];
      const currentId = parseInt(event.target.value);

      if (event.target.checked) {
        values.push(currentId);
      } else {
        const index = values.indexOf(currentId);
        values.splice(index, 1);
      }

      if (item.items && item.items.length > 0) {
        const childIds = this.pluck(item.items);
        if (event.target.checked) {
          values = values.concat(childIds);
        } else {
          childIds.push(currentId);
          values = this.modelValue.filter((id) => !childIds.includes(id));
        }
      } else if (item.parent_id) {
        if (event.target.checked && this.containsAll(values, siblingIds)) {
          values.push(item.parent_id);
        } else if (!event.target.checked) {
          const parentIndex = values.indexOf(item.parent_id);
          parentIndex > -1 && values.splice(parentIndex, 1);
        }
      }

      this.modelValue = values;
    },

    pluck(arr, key = 'id') {
      return arr.map((x) => x[key]);
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 20px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-left: 20px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.checkbox span {
  --tw-border-opacity: 1;
  background-color: transparent;
  border-color: rgba(173, 181, 189, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.checkbox input:checked ~ span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23fff' viewBox='0 0 256 256'%3E%3Cpath fill='none' d='M0 0h256v256H0z'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24' d='M216 72.005 104 184l-56-55.995'/%3E%3C/svg%3E");
}

.checkbox input:checked ~ span,
.checkbox input:indeterminate ~ span {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity));
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-color: rgba(229, 100, 0, var(--tw-border-opacity));
}

.checkbox input:checked + label,
.checkbox input:hover ~ label,
.radio input:checked + label,
.radio input:hover ~ label {
  --tw-text-opacity: 1;
  color: rgba(19, 25, 40, var(--tw-text-opacity));
}

.checkbox label,
.radio label {
  --tw-text-opacity: 1;
  color: rgba(84, 88, 102, var(--tw-text-opacity));
  margin-left: 0.5rem;
}

.checkbox-single {
  position: relative;
  padding-left: 32px;
  height: 24px;
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
  }

  .checkmark {
    .unchecked {
      @apply block;
    }
    .checked {
      @apply hidden;
    }
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    .unchecked {
      @apply hidden;
    }
    .checked {
      @apply block;
    }
  }
}
</style>
